import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueSession from 'vue-session'
import { i18n } from "@/locales/i18n";
//npm i --save vue-flag-icon
import FlagIcon from 'vue-flag-icon'

Vue.config.productionTip = false

Vue.prototype.$axios = axios;
// Vue.prototype.$axios.defaults.baseURL = 'http://192.168.0.4:8000/api/v1'

// Vue.prototype.$axios.defaults.baseURL = 'http://172.16.90.121:8000/api/v1'
// Vue.prototype.$axios.defaults.baseURL = 'http://192.168.50.124:8000/api/v1'
//Vue.prototype.$axios.defaults.baseURL = 'http://192.168.90.215:8000/api/v1'
Vue.prototype.$axios.defaults.baseURL = 'https://sdc.isignal.co.kr/api/v1'

Vue.prototype.$axios.defaults.xsrfCookieName = 'csrftoken'
Vue.prototype.$axios.defaults.xsrfHeaderName = 'X-CSRFToken'
Vue.prototype.$axios.defaults.withCredentials = false

var sessionOptions = {
  persist: true
}
Vue.use(VueSession, sessionOptions)
Vue.use(FlagIcon)

var filter = function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
