<template>
  <v-container>

    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><strong><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Privacy policy</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal (hereinafter referred to as the &quot;Company&quot;) complies with the Act on Promotion of Information and Communications Network Utilization and Information Protection, and the Personal Information Protection Act, and establishes a privacy policy based on relevant laws and regulations to protect the rights and interests of users to the best of its ability. This privacy policy refers to the methods by which the Company collects, uses, discloses, transfers, and stores personal information of users posted on the website (https://sdc.i.Signal.co.kr) and applies to SDC services and related services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>In the event of future revisions to this policy, we will announce them through website notices or email.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The privacy policy contains the following contents:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Collection of Personal Information and Collection Method</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The Company collects the following personal information from users when they sign up. The Company collects personal information directly entered by users through web documents on the website (https://sdc.i.Signal.co.kr), and in some cases, may collect some information in the form of printed documents or emails.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>| Category | Items Collected |</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>|---|---|</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>| During Sign-up | [Required] Name, Affiliation, Role, Email Address, Phone Number |</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Purpose of Collection and Use of Personal Information</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The personal information collected by the Company is used for the following purposes:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Verification of identity and personal identification during sign-up, prevention of fraudulent use and unauthorized use of the website, confirmation of intent to join, and age verification</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Handling complaints, inquiries, and grievances, preventing and managing related disputes</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Provision of SDC services</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Important notifications</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Provision of Personal Information to Third Parties</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The Company uses personal information within the scope notified in &quot;1. Collection of Personal Information and Collection Method&quot; and &quot;2. Purpose of Collection and Use of Personal Information&quot; and generally does not exceed this scope or disclose personal information to external parties without the prior consent of the information subject. However, exceptions are made in the following cases:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- When the information subject has given prior consent</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- When there is a request from an investigative agency in accordance with the procedures and methods prescribed by law for investigation purposes</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Processing and Retention Period of Personal Information</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>After the purposes of collecting and using personal information are achieved, the Company promptly destroys the personal information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The personal information is destroyed immediately after the purpose of collecting and using personal information is achieved.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>However, if it is necessary to retain the member information in accordance with the provisions of relevant laws and regulations, the Company retains the member information for a certain period of time as prescribed by the relevant laws and regulations. In this case, the Company moves the relevant personal information to a separate database (DB) or retains it in a different location.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Records related to contracts or subscription withdrawals: 5 years (Electronic Commerce Consumer Protection Act)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Records of payment and supply of goods: 5 years (Electronic Commerce Consumer Protection Act)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Records of consumer complaints or dispute resolution: 3 years (Electronic Commerce Consumer Protection Act)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Records related to advertising: 6 months (Electronic Commerce Consumer Protection Act)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Log records of users, access tracking data, website visit records: 3 months (Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Records related to electronic financial transactions: 5 years (Electronic Document and Electronic Transactions Act)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Procedure and Method of Personal Information Destruction</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Personal information is generally destroyed immediately after the purpose of processing personal information is achieved. The procedure and method of personal information destruction by the Company are as follows:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Procedure for Destruction:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp; - Information entered by users is transferred to a separate DB (in the case of paper, a separate document box) after the purpose is achieved and stored for a certain period of time according to internal policies and other relevant laws and regulations (&quot;4. Processing and Retention Period of Personal Information&quot; for reference) before being destroyed.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp; - The personal information is not used for purposes other than retention unless otherwise required by law.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Method of Destruction:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp; - Personal information printed on paper is destroyed through shredding or incineration.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp; - Personal information stored in electronic file format is deleted using technical methods that cannot replay the record.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Rights, Obligations, and Methods of Users and Legal Representatives to Exercise Them</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Users and legal representatives can inquire about or modify their registered personal information and request information deletion or processing suspension. If you wish to delete information or suspend processing, please contact the personal information protection manager, and we will promptly take action.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Measures to Ensure the Security of Personal Information</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The Company takes the following protective measures to ensure that personal information is not lost, stolen, leaked, altered, or damaged during processing:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Administrative Measures: Establishment/implementation of internal management plans, regular training of handlers, etc.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Technical Measures: Access control of personal information processing systems, installation of access control systems, encryption of important personal information, installation of security programs</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Physical Measures: Access control of relevant computer equipment</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Personal Information Protection Manager and Contact Information</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>You can report all complaints related to personal information protection to the personal information protection manager while using the website. We will promptly respond to user complaints.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>Personal Information Protection Manager:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Name: Woollim Kang</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Department/Position: i.Signal S&amp;P</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Title: Product Manager</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Email: SDC@i.Signal.co.kr</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Remedies for Rights Infringement</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>If you need to report or consult about any other personal information infringement, you can contact the following organizations:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Personal Information Dispute Mediation Committee: (www.kopico.go.kr / 1833-6972)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Korea Internet &amp; Security Agency Personal Information Protection Center (privacy.kisa.or.kr / 118 without area code)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Cyber Investigation Department, Supreme Prosecutors&apos; Office (www.spo.go.kr / 02-3480-3573, 1301 without area code)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";color:red;'>- Cyber Safety Inspectorate, National Police Agency (http://www.police.go.kr/www/security/cyber.jsp / 182 without area code)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>### Changes to the Privacy Policy</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>If there are any additions, deletions, or modifications to the contents of this privacy policy, we will notify you through the website&apos;s &apos;Notice&apos; section at least 7 days before the revision. However, if there are significant changes to user rights, such as the collection and use of personal information or provision to third parties, we will notify you at least 30 days in advance.</span></p>
  </v-container>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
  }
</script>
