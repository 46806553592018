<template>
  <v-app id="main" :style="{background: $vuetify.theme.themes[theme].background}">
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue'

export default {
  name: 'App',

  components: {
    Footer
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
};

</script>
<style>
</style>
