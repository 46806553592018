<template>
    <v-card max-width="700">
        <v-card-title>공지사항</v-card-title>
        <v-expansion-panels focusable>
            <v-expansion-panel v-for="notice in notices" :key="notice.id">
                <v-expansion-panel-header>{{ notice.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    {{ notice.content }}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import notice_api from '../api/notice.js'

export default {
    name: "NoticeList",
    components: {
    },
    props: [
    ],
    created() {
        this.getNoticeList()
    },
    mounted() {
    },
    updated() { },
    data() {
        return {
            notices: [],
            notice: [],
        };
    },
    methods: {
        getNoticeList() {
            console.log('getNoticeList')
            notice_api.getNoticesRequest(this)
            // notice_api.getPopupRequest(this)
        },
    },
    computed: {
    },
    watch: {
    },
}
</script>

<style></style>
  