<template>
  <v-container>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><strong><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Terms of Use</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 1 (Purpose)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The purpose of these terms and conditions is to stipulate the terms and procedures for convenient use of the services provided by i.Signal&apos;s website (https://sdc.i.Signal.co.kr), to define the rights, obligations, and responsibilities between i.Signal and users, and to specify other necessary matters in using the services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 2 (Definitions)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The definitions of terms used in these terms and conditions are as follows:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&quot;Service&quot; refers to all services related to data transmission provided by i.Signal&apos;s website, where users can upload and download files, and access other services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&quot;User&quot; refers to members and non-members who can use the services provided by i.Signal in accordance with these terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&quot;Member&quot; refers to those who have joined i.Signal membership and can use the services provided by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&quot;Website&quot; refers to a virtual internet space operated by i.Signal using computer and information communication facilities to provide information, goods, services, etc., to information subjects (https://sdc.i.Signal.co.kr).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&quot;Post&quot; refers to information in the form of text, files, links, etc., posted by users on the website while using the service.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 3 (Publication and Amendment of Terms and Conditions)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. i.Signal shall post the contents of these terms and conditions on the initial screen of the website so that users can view and confirm them at any time.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal may amend these terms and conditions within the scope not violating relevant laws such as the Act on Regulation of Terms and Conditions, the Personal Information Protection Act, etc. The amended terms and conditions shall take effect by being posted online for at least 7 days.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. By agreeing to these terms and conditions, users agree to regularly visit the website to check for any changes in the terms and conditions. i.Signal shall not be responsible for any damages incurred due to the user&apos;s lack of knowledge of the amended terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. If a member does not agree to the amended terms and conditions, they may request withdrawal (termination) of membership. If the member does not explicitly express their intention to withdraw from membership, it shall be deemed that they have agreed to the changes in the terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 4 (Scope and Interpretation of Terms and Conditions)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. These terms and conditions shall apply to all services provided by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. Matters not stipulated in these terms and conditions or interpretation thereof shall be governed by relevant laws or related agreements.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 5 (Membership Registration)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Membership registration for using i.Signal&apos;s services requires users to agree to the contents of the terms and conditions after authenticating themselves on the website, and then fill out the online registration form provided by i.Signal with essential information such as name, password, contact information, etc.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. However, i.Signal may refuse to accept the following applications or terminate the service contract later:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- In cases where the applicant has previously lost membership status in accordance with these terms and conditions, except when rejoining membership is approved by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Providing false information or failing to provide mandatory information as specified by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>- Applying in violation of other provisions stipulated by i.Signal due to the user&apos;s own reasons.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. The time of membership registration is deemed to be when i.Signal indicates the completion of registration in the application process.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 6 (Change of Member Information)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Members can view and modify their personal information at any time through the member information management page on the website. However, the real name necessary for service management cannot be modified.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Members must change the information on the member information management page if the information provided at the time of registration has changed. i.Signal shall not be responsible for any disadvantages caused by the member&apos;s failure to notify i.Signal of the changes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 7 (Obligation to Protect Personal Information)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal complies with relevant laws and regulations regarding the collection and protection of users&apos; personal information, such as the Telecommunications Secrets Protection Act, the Telecommunications Business Act, the Act on Promotion of Information and Communications Network Utilization and Information Protection, the Personal Information Protection Act, and guidelines on personal information protection established by the government. i.Signal also provides a privacy policy based on relevant legal provisions on the protection of personal information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 8 (Obligation to Manage Member ID and Password)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. The management responsibility for the member&apos;s ID and password lies with the member, not i.Signal. Members must not allow third parties to use their ID and password.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. If a member becomes aware that their ID and password have been stolen or used by a third party, they must immediately take measures such as modifying their password and notifying i.Signal accordingly, following i.Signal&apos;s guidance.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. In the event described in the preceding paragraph, if the member fails to notify i.Signal of the fact that their ID and password have been stolen or used by a third party, or fails to follow i.Signal&apos;s guidance even after notifying i.Signal, the member shall be responsible for any resulting disadvantages.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 9 (Notification to Users)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>When i.Signal needs to notify specific users, it may use methods such as electronic mail addresses within the service, mobile phone messages, or other means unless otherwise specified in these terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>In cases where i.Signal needs to notify all users, it may do so by posting the notification on the website for at least 7 days, in accordance with the method specified in Article 9 (Notification to Users).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 10 (Obligations of i.Signal)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. i.Signal complies with these terms and conditions and related laws and makes continuous efforts to provide sincere services for the stable operation of the services.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal has security systems in place to ensure the safe use of the services and protect the personal information of users.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. i.Signal allocates necessary personnel and systems to handle user complaints or requests for remedy arising from service use appropriately.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. If opinions or complaints raised by users regarding service use are deemed valid, i.Signal has an obligation to take appropriate measures to protect the rights and interests of users.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 11 (User&apos;s Obligations)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Users must not engage in the following actions:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Providing false information or omitting mandatory information when applying for membership registration or updating member information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. Impersonating others&apos; information.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. Changing information posted by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. Transmitting or posting information other than that specified by i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>5. Infringing upon copyrights or intellectual property rights of i.Signal, other users, or third parties.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>6. Damaging the reputation of i.Signal or interfering with its business.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>7. Posting obscene or violent messages, images, audio, or other information that goes against public morals on the service.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>8. Using the service for profit without i.Signal&apos;s consent.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>9. Engaging in other illegal or unfair actions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Users must comply with relevant laws, regulations, these terms and conditions, guidelines for use, and other notices provided by i.Signal, and must not interfere with i.Signal&apos;s business or engage in actions that disrupt it.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 12 (Provision of Services)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Services are provided 24 hours a day, 365 days a year as a general rule.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal may temporarily suspend the provision of services due to the maintenance, replacement, or breakdown of computer or telecommunication facilities, communication interruptions, or other significant reasons for operation. In the event of temporary suspension of service due to construction, i.Signal will notify users through the method specified in Article 9 (Notification to Users). However, in cases where i.Signal cannot provide prior notice due to unavoidable reasons, it may provide notice afterward.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal may conduct regular inspections as necessary for providing services and will announce the inspection times on the website.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 13 (Change of Services)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal may modify, suspend, or change part or all of the services due to operational or technical reasons related to construction and operation or other reasonable grounds. In such cases, i.Signal will notify users through the method specified in Article 9 (Notification to Users). However, in cases where i.Signal cannot provide prior notice due to unavoidable reasons, it may provide notice afterward.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 14 (Provision of Information and Advertisement Placement)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal may provide users with various necessary information related to service use through methods such as notices or electronic mail. However, except for mandatory replies related to transaction-related information and customer inquiries under relevant laws, users may refuse to receive electronic mail at any time.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal may place advertisements on the service screens or electronic mail related to service operation. Users who receive electronic mail with advertisements may refuse to receive them from i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 15 (Copyright and Management of Posted Content)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal values the content posted by members and protects it from tampering, damage, or deletion. However, i.Signal may delete or refuse to register or move the following content or data without prior notice and may restrict, suspend, or terminate the qualification of the member:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Content that severely defames or damages the reputation of other members or third parties.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. Content that violates public order and morals by spreading illegal content.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. Content that encourages illegal copying or hacking.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. Advertising content for profit.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>5. Content deemed to be associated with criminal activities.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>6. Content that infringes upon the copyright or other rights of i.Signal or other members.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>7. Content that violates the posting principles set by i.Signal or is not suitable for the nature of the bulletin board.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>8. Spam content.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>9. Other content deemed to violate relevant laws.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Copyright and other intellectual property rights for works created by i.Signal belong to i.Signal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>The copyright of the content posted by members on the service screen belongs to the posting member. However, i.Signal may not commercially use posted content without the member&apos;s consent. However, i.Signal has the right to publish within this site.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Members are prohibited from using information acquired through the service for profit or providing it to third parties for profit without i.Signal&apos;s prior consent.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal may change the posting location of original posts due to reasons such as mergers, business transfers, or integration between sites operated by i.Signal, without altering the original content of the posts.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 16 (Ownership Rights)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>i.Signal grants users the right to use accounts, content, etc., according to the conditions of use specified in these terms and conditions. Users do not possess any rights beyond those necessary for use. Users may not transfer, sell, or provide security for such rights.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 17 (Withdrawal of Membership, etc.)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Members may request withdrawal of membership at any time through the &quot;Profile Modification&quot; management menu on the initial service screen, and i.Signal must promptly process it unless there are special circumstances.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. When a member requests withdrawal, except for cases where i.Signal retains member information in accordance with relevant laws and personal information processing policies, all member data is immediately deleted.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Member-posted content is not deleted even when the member withdraws.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 18 (Restriction on Use, etc.)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. If a user violates their obligations under these terms and conditions or disrupts the normal operation of the service, i.Signal may suspend the provision of services or take measures to withdraw membership</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal is not responsible for any damages incurred by the user (or third party) due to measures taken under Paragraph 1.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. Notwithstanding Paragraph 1, if a user violates the Resident Registration Act through name forgery or payment forgery, provides or operates illegal programs in violation of the Copyright Act or the Computer Program Protection Act, engages in illegal communication or hacking in violation of the Information and Communication Network Act, distributes malicious programs, or exceeds access authority, i.Signal may take measures to withdraw membership.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. In the event of measures to withdraw membership pursuant to Paragraph 1 or 3, all user usage records specified in Article 6 are deleted, and i.Signal does not provide separate compensation for this.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>5. In the event of restrictions on use under Paragraph 1 or 3, i.Signal shall notify the user through the method specified in Article 9 (Notification to Users).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>6. Users may file an objection to the restrictions on use under this Article, and if the objection is deemed reasonable, i.Signal may immediately resume the use of the service.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 19 (Limitation of Liability)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. i.Signal is not responsible for providing services due to force majeure or equivalent unavoidable circumstances.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal is not liable for service interruptions caused by the user&apos;s fault.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. i.Signal is not responsible for the reliability, accuracy, etc., of information, data, or facts posted by users in connection with the service.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. In transactions conducted between users or between users and third parties through the service, i.Signal is not responsible for any damages incurred.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>5. i.Signal is not liable for services provided free of charge unless otherwise specified by related laws.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 20 (Governing Law and Jurisdiction)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Disputes between i.Signal and users are governed by the laws of the Republic of Korea.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. Disputes arising between i.Signal and users are subject to the exclusive jurisdiction of the court in charge of the user&apos;s address or residence at the time of filing a lawsuit, or if the user&apos;s address or residence is unclear, the jurisdiction is determined in accordance with the Civil Procedure Act.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>These terms and conditions were translated into English based on the Korean version. In case of any discrepancy between the two versions, the Korean version shall prevail.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 21 (Effectiveness and Revision of Terms)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. These terms and conditions become effective as of [insert effective date].</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. i.Signal may revise these terms and conditions to the extent that it does not violate relevant laws, such as the Act on Regulation of Terms and Conditions, the Personal Information Protection Act, and other related laws. Revised terms and conditions become effective by posting them online for at least 7 days.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>3. Users&apos; consent to these terms and conditions signifies their agreement to regularly visit the website to check for any changes in the terms and conditions. i.Signal shall not be responsible for any damages incurred due to users&apos; lack of knowledge of the revised terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>4. If a member does not agree to the revised terms and conditions, they may request withdrawal (termination) of membership. Failure to explicitly express the intention to withdraw membership shall be deemed as agreement to the revised terms and conditions.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>Article 22 (Others)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>1. Matters not stipulated in these terms and conditions shall be governed by relevant laws and regulations or general commercial practices.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>2. These terms and conditions are written in Korean, and in case of any discrepancies between the Korean version and translations into other languages, the Korean version shall take precedence.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;text-align:justify;font-size:13px;font-family:"맑은 고딕","sans-serif";'><span style='font-size:15px;line-height:107%;font-family:"Calibri","sans-serif";'>These terms and conditions were translated into English based on the Korean version. In case of any discrepancy between the two versions, the Korean version shall prevail.</span></p>

  </v-container>
</template>

<script>
  export default {
    name: 'EuraText',
  }
</script>
