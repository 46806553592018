<template>
  <v-container>

<p>아이시그널(이하 "회사"라 함)은 &nbsp;정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 본 개인정보처리방침은 본 방침이 게시된 웹사이트(https://sdc.isignal.co.kr)에서 회사가 이용자의 개인정보를 수집, 사용, 공개, 이전 및 저장하는 방법과 이용자의 개인정보를 보호하기 위해 취하는 조치를 뜻하며, SDC 서비스 및 관련 제반 서비스에 적용됩니다.</p>
<p>추후 본 방침을 개정하는 경우 웹사이트 공지사항 또는 이메일 등의 방법을 통하여 공지하겠습니다.</p>
<p>&nbsp;</p>
<p>개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</p>
<p>&nbsp;</p>
<ol>
<li>수집하는 개인정보의 항목 및 수집방법</li>
</ol>
<p>회사는 회원가입 시 이용자로부터 아래와 같은 개인정보를 수집합니다. 회사는 웹사이트(https://sdc.isignal.co.kr) 내 웹문서를 통해 이용자가 직접 입력하는 개인정보를 수집하며, 경우에 따라 출력된 형태의 종이문서 혹은 이메일로 일부 정보를 수집할 수도 있습니다.</p>
<table>
<tbody>
<tr>
<td width="301">
<p><strong>구분</strong></p>
</td>
<td width="301">
<p><strong>수집하는 항목</strong></p>
</td>
</tr>
<tr>
<td width="301">
<p>회원가입 시</p>
</td>
<td width="301">
<p>[필수] 성명, 소속, 역할, 이메일주소, 전화번호</p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="2">
<li>개인정보 수집 및 이용 목적</li>
</ol>
<p>회사가 수집한 개인정보는 다음과 같은 목적에 활용됩니다.</p>
<ul>
<li>회원가입 시 본인확인, 개인 식별, 웹사이트의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인</li>
<li>불만처리, 문의 및 민원 응대, 관련 분쟁 예방 및 처리</li>
<li>SDC 서비스 제공</li>
<li>중요 알림</li>
</ul>
<p>&nbsp;</p>
<ol start="3">
<li>개인정보의 제3자 제공</li>
</ol>
<p>회사는 개인정보를 "1. 수집하는 개인정보의 항목 및 수집방법", &rdquo;2. 개인정보 수집 및 이용 목적&rdquo;에서 고지한 범위 내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
<p>가. 정보주체가 사전에 동의한 경우</p>
<p>나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</p>
<p>&nbsp;</p>
<ol start="4">
<li>개인정보의 처리 및 보유기간</li>
</ol>
<p>회사는 개인정보 수집 및 이용목적이 달성된 후에는 개인정보를 지체 없이 파기합니다.</p>
<p>개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.</p>
<p>단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
<ul>
<li>계약 또는 청약철회 등에 관한 기록: 5년 (전자상상거래 등에서의 소비자보호에 관한 법률)</li>
<li>대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
<li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
<li>표시, 광고에 관한 기록: 6개월 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
<li>이용자의 로그기록, 접속지 추적자료, 웹사이트 방문기록: 3개월 (통신비밀보호법)</li>
<li>전자금융거래법에 의한 전자금융에 관한 기록: 5년 (전자문서 및 전자거래 기본법)</li>
</ul>
<p>&nbsp;</p>
<ol start="5">
<li>개인정보 파기절차 및 방법</li>
</ol>
<p>개인정보는 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
<p>가. 파기절차</p>
<p>이용자가 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 보관사유에 따라("4. 개인정보의 처리 및 보유기간" 참조) 일정 기간 저장된 후 파기됩니다.</p>
<p>동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</p>
<p>나. 파기방법</p>
<p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
<p>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
<p>&nbsp;</p>
<ol start="6">
<li>이용자 및 법정대리인[의 권리&middot;의무 및 그 행사방법</li>
</ol>
<p>이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 정보삭제 및 처리정지를 요청할 수도 있습니다. 정보삭제 또는 처리정지를 원하시는 경우 개인정보보호 책임자에게 연락하시면 지체 없이 조치하겠습니다.</p>
<p>&nbsp;</p>
<ol start="7">
<li>개인정보의 안전성 확보조치</li>
</ol>
<p>회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 보호조치를 강구하고 있습니다.</p>
<p>가. 관리적 조치</p>
<p>내부관리계획 수립/시행, 정기적인 취급자 교육 등</p>
<p>나. 기술적 조치</p>
<p>개인정보처리시스템 등의 접근권한 관리, 접근통제 시스템 설치, 주요 개인정보의 암호화, 보안프로그램 설치</p>
<p>다. 물리적 조치</p>
<p>관련 전산기기 등 접근통제</p>
<p>&nbsp;</p>
<ol start="8">
<li>개인정보보호 책임자 및 연락처</li>
</ol>
<p>귀하께서는 홈페이지 등을 이용하시는 과정에서 발생하는 모든 개인정보보호 관련 민원을 개인정보보호 책임자에게 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
<p>개인정보 보호책임자</p>
<ul>
<li>이름: 강울림</li>
<li>소속 및 직책: 아이시그널 S&P</li>
<li>직위: 관리자</li>
<li>이메일: SDC@isignal.co.kr</li>
</ul>
<p>&nbsp;</p>
<ol start="9">
<li>권익침해 구제방법</li>
</ol>
<p>기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.</p>
<p>개인정보 분쟁조정위원회: (<a href="http://www.kopico.go.kr">www.kopico.go.kr</a> / 1833-6972)</p>
<p>한국인터넷진흥원 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</p>
<p>대검찰청 사이버수사과 (<a href="http://www.spo.go.kr">www.spo.go.kr</a> / 02-3480-3573, 국번없이 1301)</p>
<p>경찰청 사이버안전지킴이 (http://<a href="http://www.police.go.kr/www/security/cyber.jsp%20/%20국번없이%20182">www.police.go.kr/www/security/cyber.jsp / 국번없이 182</a>)</p>
<p>&nbsp;</p>
<ol start="10">
<li>개인정보처리방침의 변경</li>
</ol>
<p>현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일전에 고지합니다</p>

  </v-container>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
  }
</script>
