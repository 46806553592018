import error from "./error_res"

export default ({
  getNoticesRequest(that) {
    let url = "/notice/notices"
    let method = "GET"
    let data = {}

    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      params: data,
      timeout: 10000,
    })
      .then((res) => {
        that.notices = res.data
      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  getAdminNoticesRequest(that) {
    let url = "/notice/admin/notices"
    let method = "GET"
    let data = {}

    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      params: data,
      timeout: 10000,
    })
      .then((res) => {
        that.admin_notices = res.data.items
      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  getPopupRequest(that) {
    let url = "/notice/popup"
    let method = "GET"
    let data = {}

    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      params: data,
      timeout: 10000,
    })
      .then((res) => {
        that.notice_popup = res.data
        that.getPopupList()

      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  doNotice(that, method) {
    let url = "/notice/notice/" + that.cur_admin_notice.id
    let data = {
      notice_id: that.cur_admin_notice.id
    }
    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      data,
      timeout: 10000,
    })
      .then((res) => {
        if (method == "GET") {
          that.cur_admin_notice=res.data
        } else if (method == "DELETE") {
          console.log('Delete NoticeId : '+res.data)
        }
      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  addNotice(that) {
    let url = "/notice/notice/" 
    let method = "POST"

    let data = {
      category: that.add_notice.category,
      title: that.add_notice.title,
      content: that.add_notice.content,
      popup: that.add_notice.popup,
      user_id: that.$session.get("id"),
      group_type: that.add_notice.group_type,
      from_time: that.add_notice.from_time,
      to_time: that.add_notice.to_time
    }
    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      data,
      timeout: 10000,
    })
      .then((res) => {
        console.log('addNotice res : ' + JSON.stringify(res.data));
        that.getAdminNotice()

      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  updateNotice(that) {
    let url = "/notice/notice/" 
    let method = "PUT"

    let data = {
      id: that.cur_admin_notice.id,
      category: that.cur_admin_notice.category,
      title: that.cur_admin_notice.title,
      content: that.cur_admin_notice.content,
      popup: that.cur_admin_notice.popup,
      user_id: that.$session.get("id"),
      group_type: that.cur_admin_notice.group_type,
      from_time: that.cur_admin_notice.from_time,
      to_time: that.cur_admin_notice.to_time
    }
    that.$axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + that.$session.get("token"),
      },
      data,
      timeout: 10000,
    })
      .then((res) => {
        console.log('updateNotice res : ' + JSON.stringify(res.data));
      })
      .catch((err) => {
        error.handle_error(err, that)
      });
  },
  getNotice(that) {
    this.doNotice(that, "GET")
  },
  deleteNotice(that) {
    this.doNotice(that, "DELETE")
  },
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  },
})
