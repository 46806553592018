import '@fortawesome/fontawesome-free/css/all.css'
//npm i @fortawesome/fontawesome-free
import 'material-design-icons-iconfont/dist/material-design-icons.css'
//npm i material-design-icons-iconfont
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md' || 'fa'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        /*
        primary: colors.blue,
        sub_primary: '#aeddff',
        secondary: colors.grey.darken1,
        accent: '#343a40',
        error: colors.red.accent3,
        table_color: '#1C1C3C',
        popup_color: '#4A5056',
        */
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#6200EE',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        accent: '#343a40',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        card: '#313030',
      },
      light: {
        //background: '#DDDDDD',
        background: '#F1F6F1',
        surface: '#DDDDDD',
        primary: '#AEC0C4',
        'primary-darken-1': '#F7F0F3',
        secondary: '#a0ced5',
        'secondary-darken-1': '#F1F7F6',
        accent: '#39AA35',
        error: '#b71c1c',
        success: '#FCFFF0',
        warning: '#0B0C00',
        card: '#FCFFF0',
        login: '#FFFFFF',
        //footer: '#E9E9E9',
        footer: '#FFFFFF',
        'footer-t': '#999999',
      },
    },
  },
});
