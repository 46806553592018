<template>
  <v-container>

<p><strong>제</strong><strong> 1 </strong><strong>조</strong><strong> (</strong><strong>목적</strong><strong>)</strong></p>
<p>이 약관은 귀하가 아이시그널이 운영하는 홈페이지(https://sdc.isignal.co.kr)에서 제공하는 서비스를 이용함에 있어 더욱 편리하게 이용할 수 있도록 이용조건 및 절차, 아이시그널과 이용자간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다</p>
<p><strong>제</strong><strong> 2 </strong><strong>조</strong><strong> (</strong><strong>정의</strong><strong>)</strong></p>
<p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
<ol>
<li>"서비스"라 함은 아이시그널 홈페이지에서 이용자가 파일 업로드 및 다운로드 하거나 조회하는 등 데이터 전송과 관련된 모든 서비스를 의미합니다.</li>
<li>"이용자"란 약관에 따라 아이시그널이 제공하는 서비스를 이용할 수 있는 회원 및 비회원을 말합니다.</li>
<li>"회원&rdquo;이라 함은 아이시그널 멤버쉽에 가입하여 아이시그널이 제공하는 서비스를 이용할 수 있는 자를 말합니다</li>
<li>&ldquo;홈페이지&rdquo;란 아이시그널이 정보, 재화, 용역 등을 정보주체에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 운영하는 가상의 인터넷 공간을 말합니다(https://sdc.isignal.co.kr).</li>
<li>"게시물"이라 함은 이용자가 서비스를 이용함에 있어 홈페이지에 게시한 문자ㆍ파일 등의 정보 형태의 글 및 각종 파일과 링크 등을 의미합니다.</li>
</ol>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 3 </strong><strong>조</strong><strong> (</strong><strong>약관의 게시와 개정</strong><strong>)</strong></p>
<p>① 아이시그널은 이 약관의 내용을 이용자가 언제든지 조회&middot;확인할 수 있도록 홈페이지 초기 화면에 게시합니다.</p>
<p>② 아이시그널은 "약관의 규제에 관한 법률", "개인정보 보호법" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있으며, 개정한 약관은 온라인에 7일 이상 게시함으로써 효력이 발생합니다..</p>
<p>③ 이용자가 이 약관에 동의하는 것은 정기적으로 홈페이지를 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 아이시그널에서 책임지지 않습니다.</p>
<p>④ 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 회원 탈퇴의 의사를 명시적으로 표명하지 않은 경우 약관의 변경 사항에 동의한 것으로 간주합니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 4 </strong><strong>조</strong><strong> (</strong><strong>약관의 적용 범위 및 해석</strong><strong>)</strong></p>
<p>① 이 약관은 아이시그널이 제공하는 모든 서비스에 관하여 적용합니다.</p>
<p>② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관련법령 또는 관련 약관에 따릅니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 5 </strong><strong>조</strong><strong> (</strong><strong>회원가입</strong><strong>)</strong></p>
<p>① 아이시그널의 서비스 이용을 위한 회원가입은 이용자가 홈페이지에서 본인인증을 거쳐 약관의 내용에 동의한 후, 아이시그널이 정한 온라인가입신청서에 성명, 비밀번호, 연락처 등 필수 입력사항을 입력하고 가입신청을 함과 동시에 서비스 이용을 승낙함을 원칙으로 합니다.</p>
<p>② 다만, 아이시그널이 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</p>
<ol>
<li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 아이시그널의 회원 재가입 승낙을 얻은 경우에는 예외로 함.<br /> 2. 허위의 정보를 기재하거나, 아이시그널이 제시하는 필수 입력사항을 기재하지 않은 경우<br /> 3. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
</ol>
<p>③ 회원가입의 성립 시기는 아이시그널이 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 6 </strong><strong>조</strong><strong> (</strong><strong>회원정보의 변경</strong><strong>)</strong></p>
<ul>
<li>회원은 홈페이지 내 회원정보 관리페이지를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명은 수정이 불가능합니다.</li>
<li>회원은 회원가입 시 기재한 사항이 변경되었을 경우 회원정보 관리페이지에서 이를 변경하여야 합니다. 회원이 변경사항을 아이시그널에 알리지 않아 발생한 불이익에 대하여 아이시그널은 책임지지 않습니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 7 </strong><strong>조</strong><strong> (</strong><strong>개인정보보호 의무</strong><strong>)</strong></p>
<p>아이시그널은 이용자의 개인정보를 수집∙ 및 보호함에 있어 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 및 정부가 제정한 개인정보보호지침 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 제공합니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 8 </strong><strong>조</strong><strong> (</strong><strong>회원의 아이디 및 비밀번호의 관리에 대한 의무</strong><strong>)</strong></p>
<p>① 회원의 아이디와 비밀번호에 관한 관리책임은 아이시그널이 아닌 회원에게 있으며, 회원은 자신의 아이디와 비밀번호를 제3자가 이용하도록 하여서는 안 됩니다.</p>
<p>② 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 본인의 비밀번호를 수정하는 등의 조치를 취해야하며 즉시 아이시그널에 통지하고 아이시그널의 안내에 따를 의무가 있습니다.</p>
<p>③ 제2항의 경우에 해당 회원이 아이시그널에 아이디와 비밀번호의 도용 사실을 통지하지 않거나, 통지한 경우라도 아이시그널의 안내에 따르지 않아 발생하는 불이익에 대한 책임은 회원에게 있습니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 9 </strong><strong>조</strong><strong> (</strong><strong>이용자에 대한 통지</strong><strong>)</strong></p>
<ul>
<li>아이시그널이 특정 이용자에게 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자 우편주소, 휴대전화 메세지, 그 밖의 방법을 이용할 수 있습니다.</li>
<li>아이시그널은 이용자 전체에 대한 통지의 경우 홈페이지에 7일 이상 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 10 </strong><strong>조</strong><strong> (</strong><strong>아이시그널의 의무</strong><strong>)</strong></p>
<p>① 아이시그널은 이 약관과 관련 법령을 준수하며, 계속적이고 안정적인 서비스를 제공하기 위하여&nbsp; 성실히 노력합니다.</p>
<p>②아이시그널은 이용자의 안전한 서비스이용 및 개인정보보호를 위해 보안시스템을 갖추고 있습니다.</p>
<p>③ 아이시그널은 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
<p>④ 아이시그널은 서비스 이용과 관련하여 이용자로부터 제기된 의견이나 불만이 정당한 경우에는 이용자의 권익구제를 위하여 적절한 조치를 취할 의무가 있습니다.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>제</strong><strong> 11 </strong><strong>조</strong><strong> (</strong><strong>이용자의 의무</strong><strong>)</strong></p>
<ul>
<li>이용자는 다음 행위를 하여서는 안 됩니다.<br /> 회원가입 신청 또는 회원정보 변경 시 허위내용의 등록<br /> 2. 타인의 정보도용<br /> 3. 아이시그널이 게시한 정보의 변경<br /> 4. 아이시그널이 정한 정보 이외의 정보 등의 송신 또는 게시<br /> 5. 아이시그널과 기타 제3자의 저작권 등 지식재산권에 대한 침해<br /> 6. 아이시그널 및 기타 제3자의 명예를 훼손시키거나 업무를 방해하는 행위<br /> 7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위<br /> 8. 아이시그널의 동의 없이 영리를 목적으로 서비스를 사용하는 행위<br /> 9. 기타 불법적이거나 부당한 행위</li>
<li>이용자는 관련 법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 사항을 준수하여야 하며, 기타 아이시그널의 업무를 방해하는 행위를 하여서는 안 됩니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 12 </strong><strong>조</strong><strong> (</strong><strong>서비스의 제공</strong><strong>)</strong></p>
<p>① 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</p>
<p>② 아이시그널은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 공사로 인한 서비스의 일시적 중단이 있을 경우, 아이시그널은 제9조(이용자에 대한 통지)에 정한 방법으로 이용자에게 통지합니다. 다만, 아이시그널이 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
<ul>
<li>아이시그널은 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간을 홈페이지에 공지합니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 13 </strong><strong>조</strong><strong> (</strong><strong>서비스의 변경</strong><strong>)</strong></p>
<p>아이시그널은 공사의 운영상, 기술상의 필요에 따라 서비스의 일부 또는 전부를 수정, 중단, 변경할 수 있으며, 이 경우 공사는 제9조(이용자에 대한 통지)에 정한 방법으로 이용자에게 통지합니다. 다만 아이시그널이 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 14 </strong><strong>조</strong><strong> (</strong><strong>정보의 제공 및 광고의 게재</strong><strong>)</strong></p>
<ul>
<li>아이시그널은 회원에게 서비스 이용과 관련하여 필요한 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 거래관련 정보 및 고객문의 등 관련법에 따른 필수적인 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.</li>
<li>아이시그널은 서비스의 운영과 관련하여 서비스 화면, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을 아이시그널에게 할 수 있습니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong>15</strong><strong>조</strong><strong>(</strong><strong>게시물의 저작권 및 관리</strong><strong>)</strong></p>
<ul>
<li>아이시그널은 회원이 작성한 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 보호합니다. 다만, 다음 각 호에 해당하는 게시물이나 자료는 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있으며, 해당 회원의 자격을 제한, 정지 또는 박탈할 수 있습니다.<br /> 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를 훼손시키는 경우<br /> 2. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우<br /> 3. 불법복제 또는 해킹을 조장하는 내용인 경우<br /> 4. 영리를 목적으로 하는 광고일 경우<br /> 5. 범죄적 행위에 결부된다고 인정되는 내용인 경우<br /> 6. 아이시그널이나 다른 회원의 저작권 혹은 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우<br /> 7. 아이시그널에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우<br /> 8. 스팸성 게시물인 경우<br /> 9. 기타 관련 법령에 위배된다고 판단되는 경우</li>
<li>아이시그널이 작성한 저작물에 대한 저작권 및 기타 지적재산권은 아이시그널에게 귀속됩니다.</li>
<li>회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 또한 아이시그널은 게시자의 동의없이 게시물을 상업적으로 이용할 수 없습니다. 다만, 비영리 목적인 경우는 그러하지 아니하며, 또한 이 사이트 내에서의 게재권을 갖습니다.</li>
<li>회원은 서비스를 이용하여 취득한 정보를 아이시그널의 사전 승낙없이 임의가공, 판매, 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
<li>아이시그널은 합병, 영업양도, 아이시그널이 운영하는 사이트 간의 통합 등의 사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를 변경할 수 있습니다.</li>
</ul>
<p><strong>&nbsp;</strong></p>
<p><strong>제</strong><strong> 16</strong><strong>조</strong><strong> (</strong><strong>권리의 귀속</strong><strong>)</strong></p>
<p>아이시그널은 서비스와 관련하여 회원에게 이 약관에서 정한 이용조건에 따라 계정, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 그 이외의 권리를 보유하지 않습니다. 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.</p>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 17 </strong><strong>조</strong><strong> (</strong><strong>회원탈퇴 등</strong><strong>)</strong></p>
<p>① 회원은 언제든지 서비스 초기화면의 &ldquo;프로필 수정&rdquo; 관리 메뉴 등을 통하여 회원 탈퇴를 신청할 수 있으며, 아이시그널은 특별한 사정이 없는 한 이를 즉시 처리하여야 합니다.</p>
<p>② 회원이 탈회를 신청할 경우, 관련법 및 개인정보처리방침에 따라 아이시그널이 회원정보를 보유하는 경우를 제외하고는 즉시 회원의 모든 데이터는 소멸됩니다.</p>
<ul>
<li>회원이 탈회를 신청하는 경우에도 회원이 작성한 게시물은 삭제되지 않습니다.</li>
</ul>
<p>&nbsp;</p>
<p><strong>제</strong><strong> 18 </strong><strong>조</strong><strong> (</strong><strong>이용제한 등</strong><strong>)</strong></p>
<p>① 아이시그널은 이용자가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 이용자에게 제공하는 서비스를 중지하거나 회원 탈퇴 조치할 수 있습니다.</p>
<p>② 제1항에 따라 이용자(또는 제3자)에게 발생한 손해에 관하여 아이시그널에서 책임지지 않습니다.</p>
<p>③ 아이시그널은 제1항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 회원 탈퇴 조치를 할 수 있습니다.</p>
<p>④ 제1항 또는 제3항에 따른 회원 탈퇴 조치 시 제 6조에서 정한 회원의 이용실적은 모두 소멸되며, 아이시그널은 이에 대해 별도로 배상하지 않습니다.</p>
<p>⑤ 본 조에 따라 아이시그널이 이용자의 서비스 이용을 제한하거나 회원 탈퇴 조치하는 경우에는 아이시그널은 제9조(이용자에 대한 통지)에 따라 통지합니다.</p>
<p>⑥ 이용자는 본 조에 따른 이용제한 등에 대해 이의신청을 할 수 있으며, 이의신청이 정당한 경우&nbsp; 아이시그널은 즉시 서비스의 이용을 재개할 수 있습니다.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>제</strong><strong> 19 </strong><strong>조</strong><strong> (</strong><strong>책임제한</strong><strong>)</strong></p>
<p>① 아이시그널은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임지지 않습니다.</p>
<p>② 아이시그널은 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임지지 않습니다.</p>
<p>③ 아이시그널은 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</p>
<p>④ 아이시그널은 이용자 간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 그로 인해 발생하는 손해에 대하여 책임지지 않습니다.</p>
<p>⑤ 아이시그널은 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>제</strong><strong> 20 </strong><strong>조</strong><strong> (</strong><strong>준거법 및 재판관할</strong><strong>)</strong></p>
<p>① 아이시그널과 이용자간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
<p>② 아이시그널과 이용자간 발생한 분쟁에 관한 소송은 제소 당시의 이용자의 개인정보에 의하고, 개인정보가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 이용자의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.</p>


  </v-container>
</template>

<script>
  export default {
    name: 'EuraText',
  }
</script>
